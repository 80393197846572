@import "config";

.tasksPage {
  .preloader {
    background-color: rgba($uiWhiteColor, 0.5);
  }
  .subHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: $contentDefaultIndent * 2;
    .filters {
      display: flex;
      .reset {
        height: 2.5em;
        margin-right: $contentDefaultIndent * 1.5;
        padding: 0 0.7em;
      }
      .search {
        width: 240px;
        height: 2.5em;
      }
      .filter {
        width: 180px;
        height: 2.5em;
        margin-left: $contentDefaultIndent * 1.5;
      }
      .button {
        height: 2.5em;
        margin-left: $contentDefaultIndent * 1.5;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .content {
    display: flex;
    flex: auto;
  }
  .container {
    display: flex;
    flex: auto;
    flex-direction: column;
    .emptyState {
      display: flex;
      flex: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 8rem;
      font-size: 1rem;
      text-align: center;
      .icon {
        svg {
          margin-bottom: $contentDefaultIndent;
          opacity: 0.2;
          font-size: 6rem;
        }
      }
      h4 {
        max-width: 370px;
        color: $uiNeutralColor;
      }
      button {
        margin-top: $contentDefaultIndent;
      }
    }
  }
}
